import { NavbarBody, NavbarTitle, useAsyncCallback } from '@orus.eu/pharaoh'
import type { Permission } from '@orus.eu/right-access-management'
import { isSubsetOf } from '@orus.eu/sets'
import { useNavigate } from '@tanstack/react-router'
import { memo, type ReactNode } from 'react'
import { trpc } from '../../client'
import { useDisconnect } from '../../lib/hooks/use-disconnect'
import { useSession } from '../../lib/session'
import { usePermissions } from '../../lib/use-permissions'
import { InviteToLoginPage } from './invite-to-login/InviteToLoginPage'
import { MenuPage } from './menu-page'

const BackofficePage = memo<{
  children: ReactNode
  requiredPermissions: Permission[]
}>(function BackofficePage({ children, requiredPermissions }) {
  const navigate = useNavigate()
  const session = useSession()
  const disconnect = useDisconnect()
  const { permissions } = usePermissions()

  const createSubscription = useAsyncCallback(async () => {
    const subscriptionId = await trpc.subscriptions.createSubscription.mutate({})

    void navigate({
      to: '/bak/v2-pending-subscriptions/$subscriptionId',
      params: { subscriptionId },
    })
  }, [navigate])

  if (!session) return <InviteToLoginPage />
  if (session.permissions?.type !== 'platform') return <InviteToLoginPage />
  if (
    !isSubsetOf(
      new Set(requiredPermissions),
      new Set(session.permissions.rolesPermissions.flatMap((role) => role.permissions)),
    )
  )
    return <InviteToLoginPage />

  return (
    <MenuPage
      menuTitle={<NavbarTitle variant="backoffice" />}
      menuBody={
        <NavbarBody
          variant="backoffice"
          disconnect={disconnect}
          createSubscription={createSubscription}
          permissions={permissions}
        />
      }
      pageContent={children}
    />
  )
})
export default BackofficePage
